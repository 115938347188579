.hamburgerMenu .hamburger-react {
  width: 40px !important;
}

.closeIcon.hamburgerMenu .hamburger-react div:nth-of-type(1) {
  top: 10px !important;
}
.openIcon.hamburgerMenu .hamburger-react div {
  height: 2px !important;
  border-radius: 25px;
}
.openIcon.hamburgerMenu .hamburger-react div:nth-of-type(1) {
  width: 21px !important;
  left: 22px !important;
  top: 15px !important;
}
.openIcon.hamburgerMenu .hamburger-react div:nth-of-type(2) {
  width: 29px !important;
  top: 25px !important;
  left: 14px !important;
  height: 1.8px !important;
}
.openIcon.hamburgerMenu .hamburger-react div:nth-of-type(3) {
  width: 35px !important;
  top: 35px !important;
  left: 9px !important;
}
