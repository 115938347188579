.headerWrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 1px 1px 0px 0px #efefef;
  background-color: var(--header-bg-color);
  transition: all 0.8s;
  z-index: 1000;
}
.leftCenterRightContainer {
  display: flex;
  justify-content: space-between;
  gap: 20%;
  align-items: center;
}

.leftRightContainer {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: 90%;
}

/* ------------ left Info--------- */
.leftInfo {
  display: flex;
  gap: 7px;
  align-items: center;
}
.logo {
  width: 46px;
  height: 46px;
  cursor: pointer;
}
.companyName {
  display: grid;
  cursor: pointer;
}
.companyName p:nth-of-type(1) {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  top: 1px;
}
.companyName p:nth-of-type(2) {
  font-size: 10px;
  font-weight: 300;
  position: relative;
  top: -6px;
}

/* ------------ center Info--------- */
.centerInfo {
  display: flex;
  justify-content: center;
  gap: 60px;
  align-items: center;
}

/* ------------ right Info--------- */
.rightInfo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(29px, auto));
  justify-content: flex-end;
  align-items: center;
}

/* ----------- last menu button --------- */
.lastMenuButton {
  border-radius: 25px;
  padding: 4px 30px;
  /* background-image: linear-gradient(
  to right,
  #1a2980 0%,
  #1b9b9a 51%,
  #1a2980 100%
); */
  /* background-size: 200% auto; */
  color: #000000;
  width: max-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  margin-left: 10px;
  transition: 0.5s;
  border: 2px solid gray;
  font-size: 16px;
}

.lastMenuButton:hover {
  /* background-position: right center; */
  color: #000000;
}

/* ----------------- media quories-------------- */
/* --------------------------------------------- */
@media (max-width: 1300px) {
  .leftCenterRightContainer {
    gap: 100px;
  }
}
@media (max-width: 1100px) {
  .leftCenterRightContainer {
    gap: 50px;
  }
}

@media (max-width: 1000px) {
  .leftCenterRightContainer {
    gap: 20px;
  }
}
