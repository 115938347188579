.headerContainer {
  --bg-color: white;
  --topHeaderHeight: 60px;
}

/* -------top header------- */
.headerContainer {
  /* height: 100vh; */
  overflow: hidden;
  background-color: var(--bg-color);
}
.topHeaderWrapper {
  width: 100%;
  height: var(--topHeaderHeight);
  display: flex;
  justify-content: center;
  background-color: var(--bg-color);
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 150;
}
.topHeaderContainer {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftInfo {
  display: flex;
  gap: 7px;
  align-items: center;
}
.logo {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.companyName {
  display: grid;
  cursor: pointer;
}
.companyName p:nth-of-type(1) {
  font-size: 23px;
  font-weight: 600;
  position: relative;
  top: 1.5px;
}
.companyName p:nth-of-type(2) {
  font-size: 9px;
  font-weight: 300;
  position: relative;
  top: -4.5px;
}

/* ----------- menus --------- */
.bottomHeaderWrapper {
  transform: translate(100%);
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: var(--topHeaderHeight);
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  background-color: var(--bg-color);
}
.showDrawer {
  transform: translate(0);
  transition: transform 0.5s;
}
.bottomHeaderContainer {
  width: calc(100% - 40px);
  padding-top: 30px;
}
.menuItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #313131;
  background: transparent;
  padding: 16px 0 15px 33px;
  transition: background 0.5s;
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 5px;
  cursor: pointer;
  /* border-bottom: 1px solid #eeeeee; */
  box-shadow: 4px 3px 6px 1px #00000012;
  border-radius: 37px;
  margin-bottom: 25px;
}
.menuItemContainer .item {
  font-size: 15px;
}
.menuItemContainer .active {
  color: black;
}
.menuItemContainer .icon {
  font-size: 17px;
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.menuItemContainer .animateIcon {
  transform: rotate(90deg);
  transition: transform 0.5s;
}
/* .menuItemContainer:hover {
  color: black;
  cursor: pointer;
} */

/* -----------dropdown menus ---------- */
.dropdownWrapper {
  min-width: max-content;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s;
  display: flex;
  justify-content: center;
}
.dropdownContainer {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 12px 0px;
}
.showDropdownContainer {
  max-height: 200px;
}
.dropdownMenu {
  position: relative;
}
.dropdownWrapper {
  width: 100%;
}
.subMenuItem {
  padding: 15px 15px;
  color: #575757;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.subMenuItem .icon {
  font-size: 5px;
  width: 17px;
  color: #515151;
  transform: rotate(-45deg);
}
.subMenuItem.active {
  color: black;
}
/* .subMenuItem:hover {
  color: black;
} */
.subMenuItem .icon path {
  stroke-width: 0; /* Increase thickness */
}

/* --------- last menu --------- */
.lastMenuWrapper {
  display: flex;
  justify-content: center;
}
.lastMenuContainer {
  border: 1px solid #919191;
  border-radius: 25px;
  padding: 9px 45px;
  margin-top: 25px;
  font-size: 15px;
}

/* ----------------media quries---------------- */
