@font-face {
  font-family: textFontFamily;
  src: url(./files/Corbel.woff2) format("woff2");
}
@font-face {
  font-family: titleFontFamily;
  src: url(./files/runalto/runalto.ttf);
}

:root {
  --title-font-family: "Aref Ruqaa";
  --text-font-family: "poppins";

  /* ---------- default ---------- */
  --default-font-family: "poppins";
}
