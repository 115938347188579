.menuContainer {
  display: flex;
  align-items: center;
}

/* ------------ menus ----------- */
.menuItem {
  width: max-content;
  color: #40415a;
  text-align: center;
  padding: 1rem;
  transition: background 0.5s;
  height: fit-content;
  cursor: pointer;
  font-size: 19px;
  font-weight: 400;
}
.menuItem:hover {
  color: #000000;
}

/* ------------ dropdown menus ----------- */
.dropdownMenu {
  position: relative;
}
.dropdownContainer {
  overflow: hidden;
  height: 0;
  transition: height 0.2s;
  position: absolute;
  top: 80px;
  min-width: 140px;
  text-align: center;
  border-radius: 6px;
  background-color: #f1f1f1;
}
.dropdownMenu:hover .dropdownContainer,
.dropdownMenu:focus .dropdownContainer,
.dropdownMenu:focus-within .dropdownContainer {
  height: var(--dropdownHeight);
  z-index: 10000;
}
